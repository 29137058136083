import { AppState } from 'core/redux/rootReducer';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { AxiosCreateRequestProps, ServiceRequestResponse } from '../../../types/serviceRequest';
import useQueryErrorHandler from '../../../../core/hooks/useQueryErrorHandler';
import { useSelector } from 'react-redux';
import { mutationCreateServiceRequest } from './gql/mutationCreateServiceRequest';

const useAxiosMakeServiceRequest = () => {
    const token = useSelector(({ auth }: AppState) => auth.token);

    const formData = new FormData();
    const queryBody = mutationCreateServiceRequest.loc?.source.body;
    const { errorHandler } = useQueryErrorHandler();

    const windowLocationsIsTestAppUrl: boolean =
        window?.location?.hostname === process.env.REACT_APP_TEST_APP_URL ||
        window?.location?.hostname === process.env.REACT_EFTEN_APP_TEST_APP_URL ||
        window?.location?.hostname === process.env.REACT_TTY_APP_TEST_APP_URL ||
        false;

    const baseUrl: string = windowLocationsIsTestAppUrl
        ? (process.env.REACT_APP_GQL_DEV_SERVER_URL as string)
        : (process.env.REACT_APP_GQL_PROD_SERVER_URL as string);

    const [axiosData, setAxiosData] = useState<ServiceRequestResponse | null>(null);
    const [axiosError, setAxiosError] = useState<AxiosError>();
    const [axiosLoading, setAxiosLoading] = useState(false);
    const [uploading, setUploading] = useState(0);

    const createRequest = ({ files, serviceData }: AxiosCreateRequestProps) => {
        if (!(!!files && files[0] instanceof File)) return;

        setAxiosLoading(true);
        setAxiosError(() => undefined);

        const filesMap: { [key: string]: [string] } = {};
        const filesKeys = Object.keys(files);
        const dummyFilesArray: null[] = [];
        filesKeys.forEach((key) => {
            dummyFilesArray.push(null);
            filesMap[key] = ['variables.serviceData.files.' + key];
            formData.append(key, files[+key]);
        });
        const operations = {
            query: queryBody,
            variables: {
                serviceData: {
                    ...serviceData,
                    files: dummyFilesArray,
                },
            },
        };
        formData.append('operations', JSON.stringify(operations));
        formData.append('map', JSON.stringify(filesMap));

        const config: AxiosRequestConfig = {
            headers: {
                'X-Auth-Token': `${token}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            method: 'post',
            baseURL: baseUrl,
            data: formData,
            onUploadProgress: (progressEvent) => {
                const progressPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploading(() => progressPercent);
            },
        };

        axios(config)
            .then((response) => {
                if ('errors' in response.data) setAxiosError(response.data.errors[0]);
                setAxiosData(() => response.data.data);
            })
            .catch((error) => {
                console.error('error', error);

                setAxiosError(error);
                errorHandler(error);
            })
            .finally(() => setAxiosLoading(false));
    };

    return { axiosError, axiosLoading, axiosData, createRequest, uploading };
};

export default useAxiosMakeServiceRequest;
