import { SALTO } from 'core/datasets/action';
import { AppState } from 'core/redux/rootReducer';
import { ISaltoReducerState, SaltoActions } from '../types';

const initState: ISaltoReducerState = {
    agreementsPermissionsMap: null,
};

export const saltoReducer = (state = initState, action: SaltoActions): ISaltoReducerState => {
    switch (action.type) {
        case SALTO.SET_AGREEMENTS_PERMISSIONS: {
            return {
                ...state,
                agreementsPermissionsMap: action.payload,
            };
        }
        default:
            return state;
    }
};

export const saltoActions = {
    setAgreementsSaltoPermissions(data: Record<string, boolean>): SaltoActions {
        return {
            type: SALTO.SET_AGREEMENTS_PERMISSIONS,
            payload: data,
        };
    },
};

export const saltoAgreementsPermissionsMap: (state: AppState) => Record<string, boolean> | null = (state) =>
    state.salto.agreementsPermissionsMap;
