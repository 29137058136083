import { AgreementObjectActions } from 'agreementObject/types/redux/agreementObjectActions';
import AgreementObjectState, {
    SelectedObjectIDSelector,
    SelectedStartDateForMultiAgreementSelector,
} from 'agreementObject/types/redux/AgreementObjectState';
import { AGREEMENT_OBJECT } from 'core/datasets/action';
import { AppState } from '../../core/redux/rootReducer';

const initialState: AgreementObjectState = {
    objects: null,
    selectedObjectID: null,
    selectedObject: null,
};

export const agreementObjectsReducer = (state = initialState, action: AgreementObjectActions): AgreementObjectState => {
    switch (action.type) {
        case AGREEMENT_OBJECT.PUT_AGREEMENT_OBJECTS: {
            return {
                ...state,
                objects: action.payload,
            };
        }
        case AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED: {
            const object = action.payload;
            return {
                ...state,
                selectedObjectID: object?.id || null,
                selectedObject: object,
            };
        }
        case AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED_BY_ID: {
            const agreementObjects = state.objects;
            const selectedObjectID = action.payload;
            const selectedObject = agreementObjects?.[selectedObjectID];

            if (selectedObject) {
                return {
                    ...state,
                    selectedObjectID: selectedObject?.id || null,
                    selectedObject: selectedObject,
                };
            }
            return state;
        }
        default:
            return state;
    }
};

export const selectedObjectIDSelector: SelectedObjectIDSelector = (state) => state.agreementObjects.selectedObjectID;
export const selectedStartDateForMultiAgreementSelector: SelectedStartDateForMultiAgreementSelector = (state) =>
    state.agreementObjects.selectedObject?.agreement?.agreementStart;

export const selectedStartDateForAgreementSelector: (state: AppState) => string | null | undefined = (state) => {
    return (
        state.agreementObjects.selectedObject?.agreement?.agreementStart ??
        state.agreements.selectedAgreement?.agreementDate
    );
};
