import { CORE, MESSAGING } from 'core/datasets/action';
import {
    AddChannelAction,
    ChangeNewMessageAction,
    ChannelViewedAction,
    CreateNewChannelAction,
    RequestChannelsAction,
    SelectChannelAction,
    SendNewMessageAction,
    SendNewMessageToServerAction,
    SetChannelMessageListAction,
    SetChannelsMap,
    SetHasUnreadMessages,
    UpdateMessagingLastActivityAction,
} from 'messaging/types/redux/messagingActions';
import { Channel, IChannelFromBE, Message } from 'messaging/types/messages';
import { DocumentNode } from 'graphql';

export const messagingActions = {
    requestChannels(): RequestChannelsAction {
        return {
            type: MESSAGING.REQUEST_CHANNELS,
        };
    },
    addChannel(channel: Channel): AddChannelAction {
        return {
            type: MESSAGING.ADD_CHANNEL,
            payload: channel,
        };
    },
    setChannelMessageList(channelId: string, list: Message[]): SetChannelMessageListAction {
        return {
            type: MESSAGING.SET_CHANNEL_MESSAGE_LIST,
            payload: {
                channelId,
                list,
            },
        };
    },
    selectChannel(channelId: string | null): SelectChannelAction {
        return {
            type: MESSAGING.SELECT_CHANNEL,
            payload: channelId,
        };
    },
    changeNewMessage(message: string): ChangeNewMessageAction {
        return {
            type: MESSAGING.CHANGE_NEW_MESSAGE,
            payload: message,
        };
    },
    sendNewMessage(): SendNewMessageAction {
        return {
            type: MESSAGING.SEND_NEW_MESSAGE,
        };
    },
    createNewChannelWithLandlord(
        gql: DocumentNode,
        rentalAgreementUuid: string,
        messagingAccessToken: string,
    ): CreateNewChannelAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: { data: { rentalAgreementUuid, messagingAccessToken } },
                callbackType: '',
            },
        };
    },
    sendNewMessageToServer(
        gql: DocumentNode,
        messagingAccessToken: string,
        messagingChannelId: string,
        messageBody: string,
    ): SendNewMessageToServerAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: { data: { messagingAccessToken, messagingChannelId, messageBody } },
                callbackType: '',
            },
        };
    },
    channelViewed(channelId: string): ChannelViewedAction {
        return {
            type: MESSAGING.CHANNEL_VIEWED,
            payload: channelId,
        };
    },
    updateMessagingLastActivity(
        gql: DocumentNode,
        messagingChannelId: string,
        messagingAccessToken: string,
    ): UpdateMessagingLastActivityAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: { data: { messagingChannelId, messagingAccessToken } },
                callbackType: '',
            },
        };
    },
    setHasUnreadMessages(status: boolean): SetHasUnreadMessages {
        return {
            type: MESSAGING.SET_HAS_UNREAD_MESSAGES,
            payload: status,
        };
    },
    setChannelsMap(payload: Record<string, IChannelFromBE>): SetChannelsMap {
        return {
            type: MESSAGING.SET_CHANNELS_MAP,
            payload,
        };
    },
};
