import { AgreementChangeRequestType } from 'agreement/classes/AgreementChangeRequestType';
import TempAgreementState from 'agreement/types/redux/TempAgreementState';
import { TempAgreementStateActions } from 'agreement/types/redux/TempAgreementStateActions';
import { AGREEMENT_TEMP } from 'core/datasets/action';

const initState: TempAgreementState = {
    selected: null,
    loading: false,
    requestType: '',
    isOpenModal: false,
    changeRequest: null,
    terminationRequestedOn: [],
};

export const tempAgreementReducer = (state = initState, action: TempAgreementStateActions): TempAgreementState => {
    switch (action.type) {
        case AGREEMENT_TEMP.LIST_ITEM_SELECTED: {
            return {
                ...state,
                selected: action.payload,
            };
        }
        case AGREEMENT_TEMP.LOADING_STATE_CHANGED: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case AGREEMENT_TEMP.REQUEST_MODAL_STATE_CHANGED: {
            if (action.payload === state.isOpenModal) return state;

            return {
                ...state,
                isOpenModal: action.payload,
            };
        }
        case AGREEMENT_TEMP.TERMINATION_PROCESS_INIT: {
            return {
                ...state,
                requestType: AgreementChangeRequestType.TERMINATION,
                changeRequest: action.payload,
            };
        }
        case AGREEMENT_TEMP.EXTENSION_PROCESS_INIT: {
            return {
                ...state,
                requestType: AgreementChangeRequestType.EXTENSION,
                changeRequest: action.payload,
            };
        }
        case AGREEMENT_TEMP.CHANGE_REQUEST_RESET: {
            return {
                ...state,
                changeRequest: null,
                requestType: '',
            };
        }
        case AGREEMENT_TEMP.CHANGE_REQUEST_DATE_CHANGED: {
            if (!state.changeRequest) return state;

            return {
                ...state,
                changeRequest: {
                    ...state.changeRequest,
                    date: action.payload,
                },
            };
        }
        case AGREEMENT_TEMP.ADD_SELECTED_TO_BLOCK_LIST: {
            return {
                ...state,
                terminationRequestedOn: [...state.terminationRequestedOn, action.payload],
            };
        }
        case AGREEMENT_TEMP.REMOVE_SELECTED_FROM_BLOCK_LIST: {
            return {
                ...state,
                terminationRequestedOn: [...state.terminationRequestedOn].filter((uuid) => uuid !== action.payload),
            };
        }
        case AGREEMENT_TEMP.REQUESTED_CHANGES_RESPONSE_RECEIVED: {
            const response = action.payload.data;

            if (!response) {
                console.error('[AGREEMENT_TEMP.REQUESTED_CHANGES_RESPONSE_RECEIVED]', response);
                return { ...state };
            }

            const responseData =
                'requestRentalAgreementTermination' in response
                    ? response['requestRentalAgreementTermination']
                    : response['requestRentalAgreementExtension'];

            const requestType =
                'requestRentalAgreementTermination' in response
                    ? AgreementChangeRequestType.TERMINATION
                    : AgreementChangeRequestType.EXTENSION;

            const { isSuccessful } = responseData;

            const disableTerminationList = [...state.terminationRequestedOn];
            if (isSuccessful && requestType === AgreementChangeRequestType.TERMINATION) {
                const currentAgreement = state.selected?.uuid;
                if (currentAgreement && !disableTerminationList.includes(currentAgreement)) {
                    disableTerminationList.push(currentAgreement);
                }
            }

            return {
                ...state,
                loading: false,
                isOpenModal: false,
                changeRequest: null,
                requestType: '',
                terminationRequestedOn: [...disableTerminationList],
            };
        }
        default:
            return state;
    }
};
