import { CORE, USER_PROFILE } from 'core/datasets/action';
import { Middleware } from 'redux';
import { i18n as i18nLarva } from '@larva.io/webcomponents-react';
import i18n from 'i18next';

export const changeLanguageMiddleware: Middleware =
    ({ getState }) =>
    (next) =>
    (action) => {
        if (action?.type === CORE.INIT_NETWORK_STATUS || action?.type === USER_PROFILE.CHANGE_LANGUAGE) {
            const store = getState();

            let language = store.userProfile.currentLang as string;
            const newLanguage = action.payload;

            if (typeof newLanguage === 'string') {
                language = newLanguage;
            }

            try {
                i18n.changeLanguage(language);
                i18nLarva.changeLanguage(language);
            } catch (reason) {
                console.log(reason);
            }
        }
        next(action);
    };
