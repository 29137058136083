import { AGREEMENT, AGREEMENT_OBJECT } from 'core/datasets/action';
import { Middleware } from 'redux';
import { AgreementStatus } from '../../../agreement/AgreementStatus';
import { AppState } from '../../../core/redux/rootReducer';
import { agreementObjectActions } from '../agreementObjectActions';
import IAgreementInstanceData from '../../../agreement/types/IAgreementData';
import IAgreementObjectInstanceData from '../../types/IAgreementObjectData';
import Agreement from '../../../agreement/classes/Agreement';
import AgreementObject from '../../classes/AgreementObject';

export function selectFirstActiveObject(agreements: IAgreementInstanceData[]): IAgreementObjectInstanceData | null {
    for (const agreement of agreements) {
        for (const object of agreement.objects) {
            if (AgreementStatus.isActive({ agreement, objectId: object.id })) {
                return object;
            }
        }
    }

    return null;
}

const agreementObjectMiddleware: Middleware =
    ({ getState, dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        if (action?.type === AGREEMENT_OBJECT.PUT_AGREEMENT_OBJECTS) {
            const store = getState() as AppState;
            const objects = action.payload;

            const selectedObject = store.agreementObjects.selectedObject;
            const selectedAgreement = store.agreements.selectedAgreement;

            const hasSelectedObjectIdInObjects = selectedObject && selectedObject.id in objects;
            const isSelectedAgreementActive = AgreementStatus.isActive({
                agreement: selectedAgreement,
                objectId: selectedObject?.id ?? null,
            });

            if (hasSelectedObjectIdInObjects && isSelectedAgreementActive) {
                return;
            }

            const agreements = store.agreements.map;

            if (!agreements) {
                console.error('[initSelectObject]: No agreements');
                return;
            }

            const firstActiveObject = selectFirstActiveObject(Object.values(agreements));

            if (selectedObject) {
                dispatch(agreementObjectActions.selectAgreementObject(firstActiveObject));
            } else {
                dispatch(agreementObjectActions.selectAgreementObject(null));
            }
        }

        if (action?.type === AGREEMENT.AGREEMENT_SELECTED) {
            const store = getState() as AppState;
            const selectedAgreement = action.payload;

            const selectedObjectId = store.agreementObjects.selectedObjectID;

            if (!selectedAgreement) {
                return;
            }

            if (
                !selectedObjectId ||
                !Agreement.hasObject(selectedAgreement, selectedObjectId) ||
                !AgreementStatus.isActive({ agreement: selectedAgreement, objectId: selectedObjectId })
            ) {
                const firstActiveObject = selectFirstActiveObject([selectedAgreement]);
                if (firstActiveObject) {
                    dispatch(agreementObjectActions.selectAgreementObject(firstActiveObject));
                }
            }
        }

        if (action?.type === AGREEMENT.DATA_UPDATED) {
            const agreementInstanceMap = action.payload.map as Record<number, IAgreementInstanceData>;

            if (!agreementInstanceMap) return;

            const agreementObjects: { [id: number]: IAgreementObjectInstanceData } = {};

            for (const agreement of Object.values(agreementInstanceMap)) {
                for (const object of agreement.objects) {
                    agreementObjects[object.id] = new AgreementObject(object).all;
                }
            }

            dispatch(agreementObjectActions.putAgreementObjects(agreementObjects));
        }
    };

export default agreementObjectMiddleware;
