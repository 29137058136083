import { AUTH, AUTH_TEMP } from 'core/datasets/action';
import {
    AuthActions,
    ChangeFieldAction,
    LoginAsyncAction,
    LoginSuccessAction,
    PrefillEmailFieldAction,
    RequestRefreshTokenAction,
    SetAuthErrorMessage,
    TokenRefreshedAction,
    TokensChangeAction,
} from 'core/types/redux/authActions';
import { AuthState } from 'core/types/redux/AuthState';
import { ChangeEvent } from 'react';
import { ClearLoadingState } from 'tenantUser/types/redux/TempAuthActions';

export const requestToRefreshExpiredToken = (): RequestRefreshTokenAction => {
    return {
        type: AUTH.REQUEST_TO_REFRESH_EXPIRED_TOKEN,
    };
};

export const refreshExpiredToken = (token: string): TokenRefreshedAction => {
    return {
        type: AUTH.EXPIRED_TOKEN_REFRESHED,
        payload: token,
    };
};

export const changeTokens = (token: string, refreshToken: string): TokensChangeAction => {
    return {
        type: AUTH.TOKENS_CHANGED,
        payload: {
            token,
            refresh_token: refreshToken,
        },
    };
};

export const setLogout = (): AuthActions => {
    return {
        type: AUTH.LOGOUT,
    };
};

export const setLoginSuccess = (payload: AuthState): LoginSuccessAction => {
    return {
        type: AUTH.LOGIN_SUCCESS,
        payload: payload,
    };
};

export const changeFieldValue = ({ target }: ChangeEvent<HTMLInputElement>): ChangeFieldAction => {
    const { name, value } = target;
    return {
        type: AUTH.CHANGE_FIELD,
        fieldName: name,
        payload: {
            value,
            invalid: !value.length,
        },
    };
};

export const prefillEmailField = (email: string): PrefillEmailFieldAction => {
    return {
        type: AUTH.PREFILL_EMAIL_FIELD,
        payload: email,
    };
};

export const setAuthErrorMessage = (errorMessage: string): SetAuthErrorMessage => {
    return {
        type: AUTH.SET_ERROR_MESSAGE,
        payload: errorMessage,
    };
};

export const startLogin = (): LoginAsyncAction => {
    return {
        type: AUTH.LOGIN_ASYNC,
    };
};

export const authActions = {
    clearLoadingState(): ClearLoadingState {
        return { type: AUTH_TEMP.CLEAR_LOADING_STATE };
    },
    setLogout(): AuthActions {
        return {
            type: AUTH.LOGOUT,
        };
    },
};
