import { Middleware } from 'redux';
import { AGREEMENT, AGREEMENT_OBJECT, AGREEMENT_TEMP, AUTH, CORE } from 'core/datasets/action';
import { agreementActions } from '../agreementActions';
import { AppState } from '../../../core/redux/rootReducer';
import NetworkStatus from 'core/types/NetworkStatus';
import AgreementsGraphQLRequestVariables from '../../types/AgreementsGraphQLRequestVariables';
import { ORDER_BY, ORDER_DIR } from '../../../core/hooks/useQueryHelper';
import { queryRentalAgreementDocument } from '../../api/gql/useQueryRentalAgreements/queryRentalAgreementDocument';
import IAgreementPropsData from '../../types/IAgreementPropsData';
import { IAgreementListViewData } from '../../types/IAgreementListViewData';
import Agreement from '../../classes/Agreement';
import AgreementListViewItem from '../../classes/AgreementListViewItem';
import IAgreementInstanceData from '../../types/IAgreementData';
import { coreActions } from '../../../core/redux/actions/coreActions';
import { NotificationAlert } from '../../../core/classes/NotificationAlert';
import { handleAgreementsAllReceived, handleAgreementsInitReceived } from './handleAgreementsReceived';
import { AgreementStatus } from '../../AgreementStatus';
import { DateTime } from 'luxon';
import { AgreementChangeRequestType } from '../../classes/AgreementChangeRequestType';
import {
    AgreementExtensionRequestResponseData,
    AgreementTerminationRequestResponseData,
} from '../../types/ReceivedAgreementsChangeRequestResponseAction';

const agreementMiddleware: Middleware =
    ({ getState, dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        const state: AppState = getState();

        switch (action?.type) {
            case AUTH.LOGIN_SUCCESS: {
                dispatch(agreementActions.callInitAgreementsFetching());
                break;
            }

            case AGREEMENT.FETCH_AGREEMENTS_ASYNC: {
                dispatch(agreementActions.callInitAgreementsFetching());
                break;
            }

            case AGREEMENT.FETCH_ALL_AGREEMENTS: {
                dispatch(agreementActions.changeLoadingState(true));

                const callbackAction = AGREEMENT.ALL_AGREEMENTS_RECEIVED;
                const variables: AgreementsGraphQLRequestVariables = {
                    orderDir: ORDER_DIR.desc,
                    orderAgreementsBy: ORDER_BY.agreementDate,
                };

                dispatch(agreementActions.fetchAllAgreements(queryRentalAgreementDocument, variables, callbackAction));
                break;
            }

            case AGREEMENT.INIT_AGREEMENTS_FETCHING: {
                dispatch(agreementActions.changeLoadingState(true));

                const callbackAction = AGREEMENT.INIT_AGREEMENTS_RECEIVED;
                const variables: AgreementsGraphQLRequestVariables = {
                    orderDir: ORDER_DIR.desc,
                    orderAgreementsBy: ORDER_BY.agreementDate,
                };

                dispatch(agreementActions.fetchAllAgreements(queryRentalAgreementDocument, variables, callbackAction));
                break;
            }

            case AGREEMENT.DATA_UPDATED: {
                dispatch(agreementActions.changeLoadingState(false));

                const payload = action.payload as {
                    list: IAgreementListViewData[];
                    map: { [id: number]: IAgreementInstanceData };
                    type?: typeof AGREEMENT.ALL_AGREEMENTS_RECEIVED | typeof AGREEMENT.INIT_AGREEMENTS_RECEIVED;
                };
                const { list: agreementsList, map: agreementsMap, type: agreementsActionType } = payload ?? {};

                if (agreementsActionType === AGREEMENT.INIT_AGREEMENTS_RECEIVED) {
                    const selectedAgreementBefore = state.agreements.selectedAgreement;
                    const selectedObjectID = state.agreementObjects.selectedObjectID;

                    handleAgreementsInitReceived(payload, selectedAgreementBefore, selectedObjectID, dispatch);
                }

                if (
                    agreementsActionType === AGREEMENT.ALL_AGREEMENTS_RECEIVED &&
                    agreementsList?.length &&
                    agreementsMap
                ) {
                    const tempSelectedAgreement = state.tempAgreements.selected;

                    handleAgreementsAllReceived(payload, tempSelectedAgreement, dispatch);
                }
                break;
            }

            case CORE.APP_ACTIVE_STATUS_CHANGED: {
                const isActive = action.payload as boolean;

                if (isActive) {
                    const token = state.auth.token;
                    const isConnected = state.core.networkStatus.isConnected;

                    if (isConnected && token) dispatch(agreementActions.fetchAgreementsAsync(token));
                }
                break;
            }

            case CORE.INIT_NETWORK_STATUS: {
                const networkStatus = action.payload as NetworkStatus;

                if (networkStatus.isConnected) {
                    const token = state.auth.token;

                    if (token) dispatch(agreementActions.fetchAgreementsAsync(token));
                }
                break;
            }

            case AGREEMENT.ALL_AGREEMENTS_RECEIVED: {
                const agreements: IAgreementPropsData[] = action?.payload?.data?.rentalAgreements;

                const agreementsList: IAgreementListViewData[] = [];
                const agreementsMap: Record<string, IAgreementInstanceData> = {};

                for (const agreementData of agreements) {
                    try {
                        const agreement = new Agreement(agreementData);
                        agreementsList.push(new AgreementListViewItem(agreementData).listViewData);
                        agreementsMap[String(agreement.id)] = agreement.all;
                    } catch (error) {
                        console.error('Error parsing agreement data: ', agreementData.id, { error });
                    }
                }

                dispatch(
                    agreementActions.updateAgreementsData({
                        list: agreementsList,
                        map: agreementsMap,
                        type: action?.type,
                    }),
                );
                break;
            }

            case AGREEMENT.INIT_AGREEMENTS_RECEIVED: {
                const agreements: IAgreementPropsData[] = action?.payload?.data?.rentalAgreements;

                const agreementsList: IAgreementListViewData[] = [];
                const agreementsMap: Record<string, IAgreementInstanceData> = {};

                for (const agreementData of agreements) {
                    try {
                        const agreement = new Agreement(agreementData);
                        agreementsList.push(new AgreementListViewItem(agreementData).listViewData);
                        agreementsMap[String(agreement.id)] = agreement.all;
                    } catch (error) {
                        console.error('Error parsing agreement data: ', agreementData.id, { error });
                    }
                }

                dispatch(
                    agreementActions.updateAgreementsData({
                        list: agreementsList,
                        map: agreementsMap,
                        type: action?.type,
                    }),
                );
                break;
            }

            case AGREEMENT_TEMP.LIST_ITEM_SELECTED_BY_ID: {
                const agreementsMap = state.agreements.map;
                const id = action.payload;

                if (id) dispatch(agreementActions.selectAgreement(agreementsMap[id]));
                break;
            }

            case AGREEMENT.LICENSE_PLATES_CHANGED: {
                dispatch(agreementActions.callFetchAllAgreementsDataUpdate());
                dispatch(
                    coreActions.notification.set(
                        new NotificationAlert({
                            message: 'documents.rentalAgreement.saved',
                            level: NotificationAlert.SUCCESS,
                        }),
                    ),
                );
                break;
            }

            case AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED_BY_ID: {
                const objectId: number = action.payload;

                const agreementsMap = state.agreements.map;
                if (!agreementsMap) {
                    console.error('No agreements for selection');
                    return;
                }

                for (const agreement of Object.values(agreementsMap)) {
                    for (const object of agreement.objects) {
                        if (AgreementStatus.isActive({ agreement, objectId: object.id }) && object.id === objectId) {
                            dispatch(agreementActions.setSelectedObjectAgreement(agreement));
                            break;
                        }
                    }
                }
                break;
            }

            case AGREEMENT_TEMP.TERMINATION_PROCESS_CALLED: {
                const objectAgreementUuid = action.payload;
                dispatch(agreementActions.openChangeRequestModal());

                const agreement = state.tempAgreements.selected;

                if (!agreement) return;

                dispatch(
                    agreementActions.initTerminationProcess({
                        objectAgreementUuid: objectAgreementUuid || null,
                        date: DateTime.now(),
                    }),
                );
                break;
            }

            case AGREEMENT_TEMP.EXTENSION_PROCESS_CALLED: {
                const objectAgreementUuid = action.payload;
                dispatch(agreementActions.openChangeRequestModal());

                const agreement = state.tempAgreements.selected;
                if (!agreement) return;

                let agreementEnd = agreement.agreementEnd;

                if (objectAgreementUuid) {
                    const objectAgreement = agreement.objects.find(
                        (objectAgreement) => objectAgreement.agreement?.uuid === objectAgreementUuid,
                    );
                    agreementEnd = objectAgreement?.agreement?.agreementEnd || agreementEnd;
                }

                dispatch(
                    agreementActions.initExtensionProcess({
                        date: agreementEnd === null ? null : DateTime.fromSQL(agreementEnd).plus({ days: 1 }),
                        objectAgreementUuid: objectAgreementUuid,
                    }),
                );
                break;
            }

            case AGREEMENT_TEMP.REQUEST_MODAL_STATE_CHANGED: {
                if (!action.payload) {
                    dispatch(agreementActions.clearChangeRequestCall());
                }
                break;
            }

            case AGREEMENT_TEMP.REQUESTED_CHANGES_RESPONSE_RECEIVED: {
                const response = action.payload?.data as
                    | AgreementExtensionRequestResponseData
                    | AgreementTerminationRequestResponseData;

                if (!response) {
                    return;
                }

                const responseData =
                    'requestRentalAgreementTermination' in response
                        ? response['requestRentalAgreementTermination']
                        : response['requestRentalAgreementExtension'];

                const requestType =
                    'requestRentalAgreementTermination' in response
                        ? AgreementChangeRequestType.TERMINATION
                        : AgreementChangeRequestType.EXTENSION;

                const { isSuccessful, message: errorMessage } = responseData || {};
                const successMessage = `documents.rentalAgreement.confirmation.notices.onSuccess.${requestType}`;
                const successAlert = new NotificationAlert({
                    message: successMessage,
                    level: NotificationAlert.SUCCESS,
                });
                const errorAlert = new NotificationAlert({ message: errorMessage, level: NotificationAlert.ERROR });

                dispatch(coreActions.notification.set(isSuccessful ? successAlert : errorAlert));

                const uuid = state.tempAgreements.selected?.uuid;
                const delay5min = 1000 * 60 * 5;

                // This is ugly and hacky solution. It blocks 'Request termination button for 5 minutes'
                setTimeout(() => {
                    dispatch({
                        type: AGREEMENT_TEMP.REMOVE_SELECTED_FROM_BLOCK_LIST,
                        payload: uuid,
                    });
                }, delay5min);
                break;
            }
        }
    };

export default agreementMiddleware;
