import { FILE_TEMP } from 'core/datasets/action';
import TempFilesState from 'file/types/TempFilesState';
import { TempFilesStateActions } from 'file/types/TempFilesStateActions';
import FileDto from '../classes/FileDto';
import { b64toBlob } from '../../core/saga/workers/handleBase64DataConversion';

const initState: TempFilesState = {
    loading: false,
    selected: null,
    downloading: false,
    previewLoading: false,
    url: '',
    isBroken: false,
};

export const tempFilesReducer = (state = initState, action: TempFilesStateActions): TempFilesState => {
    switch (action.type) {
        case FILE_TEMP.DATA_URL_GENERATED: {
            return {
                ...state,
                url: action.payload,
            };
        }
        case FILE_TEMP.LOADING_STATE_CHANGED: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case FILE_TEMP.PREVIEW_LOADING_STATE_CHANGED: {
            return {
                ...state,
                previewLoading: action.payload,
            };
        }
        case FILE_TEMP.DOWNLOADING_STATE_CHANGED: {
            return {
                ...state,
                downloading: action.payload,
            };
        }
        case FILE_TEMP.LIST_ITEM_SELECTED: {
            return {
                ...state,
                selected: action.payload,
                isBroken: false,
            };
        }
        case FILE_TEMP.RESET_SELECTED: {
            return {
                ...initState,
            };
        }
        case FILE_TEMP.MARK_SELECTED_BROKEN: {
            return {
                ...state,
                isBroken: true,
            };
        }
        case FILE_TEMP.UPDATE_SELECTED_FILE_DATA: {
            const fileRawData = action.payload?.data?.getFile;

            const fileData = new FileDto(fileRawData).all;

            if (fileRawData) {
                const nextUrl = fileData.b64Data ? b64toBlob(fileData.b64Data, fileData.mimeType) : state.url;

                return {
                    ...state,
                    selected: fileData,
                    isBroken: false,
                    url: nextUrl,
                };
            }

            return { ...state };
        }
        default:
            return state;
    }
};
