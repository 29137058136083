import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgreementStatus } from 'agreement/AgreementStatus';
import { agreementObjectActions } from 'agreementObject/redux/agreementObjectActions';
import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import { AppState } from 'core/redux/rootReducer';
import AgreementObjectSelect from 'pages/Settings/components/AgreementObjectSelect';
import { co2Actions } from 'co2Meters/redux/co2Actions';
import useQueryRentalAgreements from 'agreement/api/gql/useQueryRentalAgreements';
import { AGREEMENT_OBJECT } from 'core/datasets/action';
import { handleAgreementSelected } from 'smartHome/inception/saga/poller';

const AgreementObjectSelectContainer = (): JSX.Element => {
    const dispatch = useDispatch();

    const agreements = useSelector(({ agreements }: AppState) => agreements.map);
    const selectedObjectID = useSelector(({ agreementObjects }: AppState) => agreementObjects.selectedObjectID);
    const agreementObjects: { [p: number]: IAgreementObjectInstanceData } = {};

    const { refetchRentalAgreements } = useQueryRentalAgreements();

    // filter objects with active agreements
    if (agreements && Object.values(agreements).length) {
        for (const agreement of Object.values(agreements)) {
            for (const object of agreement.objects) {
                if (AgreementStatus.isActive({ agreement, objectId: object.id })) {
                    agreementObjects[object.id] = object;
                }
            }
        }
    }

    if (!Object.values(agreementObjects).length) {
        //reset
        dispatch(agreementObjectActions.selectAgreementObject(null));
    }

    const handleSelect = (event: ChangeEvent<{ value: unknown }>): void => {
        const id = Number.parseInt(event?.target.value as string);
        dispatch(co2Actions.cleanCO2AndMeters());
        dispatch({
            type: AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED_BY_ID,
            payload: id,
        });
        refetchRentalAgreements();
        handleAgreementSelected();
    };

    return (
        <AgreementObjectSelect
            handleSelect={handleSelect}
            loading={false}
            agreementObjects={agreementObjects}
            selectedObjectId={selectedObjectID || null}
        />
    );
};

export default AgreementObjectSelectContainer;
