import { FILE } from 'core/datasets/action';
import { FilesActions } from 'file/types/FilesActions';
import FilesState from 'file/types/FilesState';
import IListViewData from '../../core/types/IListViewData';
import IFileData from '../types/IFileData';
import FileDto from '../classes/FileDto';
import FileListViewItem from '../classes/FileListViewItem';

const initState: FilesState = {
    list: [],
    map: {},
};

export const filesReducer = (state = initState, action: FilesActions): FilesState => {
    switch (action.type) {
        case FILE.MARK_SELECTED_LIST_ITEM_BROKEN: {
            return {
                ...state,
                list: action.payload,
            };
        }
        case FILE.ALL_FILES_RECEIVED: {
            const files = action.payload.data.getFiles;

            const list: IListViewData[] = [];
            const map: { [id: number]: IFileData } = {};

            for (const file of files) {
                const fileData = new FileDto(file).all;

                if (file.id in map) continue;

                list.push(new FileListViewItem(file).listViewData);
                map[file.id] = fileData;
            }

            return { ...state, list, map };
        }
        default:
            return state;
    }
};
