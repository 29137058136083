export const LOCALE = {
    MOMENT: {
        en: 'en-gb',
        et: 'et',
        es: 'es',
        pl: 'pl',
        de: 'de',
        lv: 'lv',
        lt: 'lt',
        ro: 'ro',
    },
} as const;
