import { isEmpty } from 'lodash';
import { agreementActions } from '../agreementActions';
import { AgreementStatus } from '../../AgreementStatus';
import IAgreementInstanceData from '../../types/IAgreementData';
import { selectFirstActiveAgreementFromMap, selectFirstAgreementFromListOrMap } from '../../utils';
import { AgreementType } from '../../classes/AgreementType';
import { IAgreementListViewData } from '../../types/IAgreementListViewData';
import { AGREEMENT } from '../../../core/datasets/action';
import { Dispatch } from 'redux';

interface IPayload {
    list: IAgreementListViewData[];
    map: { [id: number]: IAgreementInstanceData };
    type?: typeof AGREEMENT.ALL_AGREEMENTS_RECEIVED | typeof AGREEMENT.INIT_AGREEMENTS_RECEIVED;
}

export const handleAgreementsInitReceived = (
    payload: IPayload,
    selectedAgreementBefore: IAgreementInstanceData | null,
    selectedObjectID: number | null,
    dispatchCallback: Dispatch,
): void => {
    const { list: agreementsList, map: agreementsMap } = payload ?? {};

    if ((isEmpty(agreementsList) || isEmpty(agreementsMap)) && selectedAgreementBefore) {
        dispatchCallback(agreementActions.setSelectedObjectAgreement(null));
        return;
    }

    if (
        selectedAgreementBefore &&
        agreementsList?.[selectedAgreementBefore.id] &&
        AgreementStatus.isActive({
            agreement: selectedAgreementBefore,
            objectId: selectedObjectID || null,
        })
    ) {
        return;
    }

    const selectedAgreement: IAgreementInstanceData | null = selectFirstActiveAgreementFromMap(
        agreementsMap,
        selectedAgreementBefore?.id,
        AgreementType.isMultiObject(selectedAgreementBefore) ? selectedObjectID : null,
    );

    dispatchCallback(agreementActions.setSelectedObjectAgreement(selectedAgreement));
};

export const handleAgreementsAllReceived = (
    payload: IPayload,
    tempSelectedAgreement: IAgreementInstanceData | null,
    dispatchCallback: Dispatch,
): void => {
    const { list: agreementsList, map: agreementsMap } = payload ?? {};

    const tempSelectedAgreementId = tempSelectedAgreement?.id || null;

    const selectedAgreement = selectFirstAgreementFromListOrMap(tempSelectedAgreementId, agreementsList, agreementsMap);

    if (selectedAgreement) {
        dispatchCallback(agreementActions.selectAgreement(selectedAgreement));
        return;
    }

    dispatchCallback(agreementActions.selectAgreement(Object.values(agreementsMap)[0]));
};
