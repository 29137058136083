const mode = process.env.NODE_ENV;
const isProduction = mode === 'production';

const productionConfig = {
    deviceProxy: 'wss://broker.fentrica.com',
    deviceTimeout: 10000,
    videoProxy: 'wss://video.broker.fentrica.com/',
    mantisUrl: 'https://api.building.fentrica.com/mantis/orgs',
    timeout: 10000,
    wsClientTimeOut: 30000,
} as const;

const developmentConfig = {
    deviceProxy: 'wss://broker.test.fentrica.com',
    deviceTimeout: 10000,
    videoProxy: 'wss://video.broker.test.fentrica.com/',
    mantisUrl: 'https://api.building.test.fentrica.com/mantis/orgs',
    timeout: 10000,
    wsClientTimeOut: 30000,
} as const;

export default isProduction ? productionConfig : developmentConfig;
