import { gql } from '@apollo/client';

// Have to override graphql-let code generation, because it generates outdated request
export const LoginGQLDocument = gql`
    mutation LogIn($input: LogInInput!) {
        LogIn(input: $input) {
            user {
                id
                email
                firstName
                lastName
                connectedTenants {
                    id
                    email
                    phone
                    ownedBankAccountNumber
                    iban
                    rentalAgreements {
                        id
                    }
                }
            }
            token
            refresh_token
        }
    }
`;
