import { Link } from 'react-router-dom';
import { isString } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import { PageContextType } from 'core/types/pageContext';
import AddNewItemTriggerButton from '../../buttons/AddNewItemTriggerButton/AddNewItemTriggerButton';
import FilterIconTriggerButton from '../../buttons/FilterIconTriggerButton/FilterIconTriggerButton';
import { FiltersState } from 'core/types/redux/filtersState';
import './styles/AppHeader.css';

export type Props = {
    title: string | JSX.Element;
    path?: string;
    handleCloseSlidePanel?: () => void;
    filters?: FiltersState;
    classes?: string;
    context?: PageContextType;
};

interface ReturnBtnProps {
    path?: string;
}
const ReturnBtn = ({ path }: ReturnBtnProps): JSX.Element | null => {
    return !!path ? (
        <Link to={path} className="app-header__return-btn-link">
            <IconButton className="app-header__return-btn bta-icon-chevron-left" />
        </Link>
    ) : null;
};

/**
 * Header component: shows current location, provides with menu button/filters, logo
 * @param args
 * @constructor
 */
export const AppHeader = ({
    title,
    context,
    handleCloseSlidePanel,
    filters,
    path = '',
    classes = '',
}: Props): JSX.Element | null => {
    if (!title) return null;

    const pageContext = context as PageContextType;
    // @ts-ignore
    return (
        <div className={'app-header ' + classes}>
            <div className="app-header__wrapper">
                <ReturnBtn path={path} />
                {isString(title) ? <h2 className="title">{title}</h2> : title}
                <AddNewItemTriggerButton pageContext={pageContext} onClick={handleCloseSlidePanel} />
                <FilterIconTriggerButton filters={filters} pageContext={pageContext} onClick={handleCloseSlidePanel} />
            </div>
        </div>
    );
};

export default AppHeader;
