import { MessagingReducerState } from '../types/redux/messagingState';
import { MessagingActions } from '../types/redux/messagingActions';
import { AUTH, MESSAGING } from 'core/datasets/action';
import { Message } from 'messaging/types/messages';

const initState: MessagingReducerState = {
    channels: {},
    channelsInLoad: false,
    newMessage: '',
    currentChannelId: null,
    previousChannelId: null,
    token: null,
    hasUnreadMessages: false,
    channelsMap: null,
};

function filterChannelMessages(msg: Message) {
    return msg.channelId && msg.text?.trim().length;
}

export const messagingReducer = (state = initState, action: MessagingActions): MessagingReducerState => {
    switch (action.type) {
        case MESSAGING.REQUEST_CHANNELS:
            return {
                ...state,
                channelsInLoad: true,
            };

        case MESSAGING.CHANGE_CHANNEL_FIELD: {
            const { field, channelId, value } = action.payload ?? {};
            return {
                ...state,
                channels: {
                    ...state.channels,
                    [channelId]: {
                        ...state.channels[channelId],
                        [field]: value,
                    },
                },
            };
        }

        case MESSAGING.SELECT_CHANNEL:
            return {
                ...state,
                currentChannelId: action.payload,
                previousChannelId: state.currentChannelId,
            };

        case MESSAGING.CHANGE_NEW_MESSAGE:
            return { ...state, newMessage: action.payload };

        case MESSAGING.ADD_CHANNEL: {
            const { id, messages } = action.payload ?? {};

            return {
                ...state,
                channelsInLoad: false,
                channels: {
                    ...state.channels,
                    ...{
                        [id]: {
                            ...action.payload,
                            messages: (state.channels[id] ? state.channels[id].messages : messages) ?? [],
                        },
                    },
                },
            };
        }

        case MESSAGING.SET_CHANNEL_MESSAGE_LIST: {
            const { channelId, list } = action.payload ?? {};
            return {
                ...state,
                channels: {
                    ...state.channels,
                    ...(state.channels[channelId]
                        ? {
                              [channelId]: {
                                  ...state.channels[channelId],
                                  messages: list.filter(filterChannelMessages),
                              },
                          }
                        : {
                              [channelId]: {
                                  messages: list.filter(filterChannelMessages),
                                  id: channelId,
                                  name: '',
                                  lastMessage: '',
                                  lastMessageTimestamp: null,
                                  hasUnread: false,
                              },
                          }),
                },
            };
        }

        case MESSAGING.ADD_CHANNEL_MESSAGE: {
            const { channelId, id } = action.payload;

            if (!state.channels[channelId]) {
                return state;
            }

            const messages = state.channels[channelId]?.messages || [];

            const channelState = state.channels[channelId];
            const viewed = Math.floor(Date.now() / 1000);

            return {
                ...state,
                channels: {
                    ...state.channels,
                    [channelId]: {
                        ...channelState,
                        messages: messages.find((item) => item.id === id)
                            ? messages.map((item) => (item.id === id ? { ...action.payload } : item))
                            : [...messages, action.payload],
                        viewed,
                        lastMessage: action.payload?.text || '',
                        lastMessageTimestamp: action.payload?.timestamp || viewed,
                    },
                },
            };
        }

        case MESSAGING.CHANNEL_VIEWED: {
            const channelId = action.payload;

            // Current moment in seconds
            const viewed = Math.floor(Date.now() / 1000);

            return {
                ...state,
                channels: {
                    ...state.channels,
                    [channelId]: { ...state.channels[channelId], viewed },
                },
            };
        }

        case MESSAGING.SET_HAS_UNREAD_MESSAGES: {
            return {
                ...state,
                hasUnreadMessages: action.payload,
            };
        }

        case AUTH.LOGOUT: {
            return { ...initState };
        }

        case MESSAGING.SET_CHANNELS_MAP: {
            return { ...state, channelsMap: action.payload };
        }

        default:
            return state;
    }
};
