import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { menuMiddleware } from 'core/redux/middleWares/menuMiddleware';
import { windowSizeListenerMiddleware } from 'core/redux/middleWares/windowSizeListenerMiddleware';
import { persistedRootReducer } from 'core/redux/rootReducer';
import { larvaActionsQueueMiddleware } from 'smartHome/larva/redux/middlewares/larvaActionsQueueMiddleware';
import { larvaWSConnectionsMiddleware } from 'smartHome/larva/redux/middlewares/larvaWSConnectionsMiddleware';
import { larvaWsRequestsMiddleware } from 'smartHome/larva/redux/middlewares/larvaWsRequestsMiddleware';
import {
    changeLanguageMiddleware,
    languageMiddleware,
    logoutMiddleware,
    refreshTokenMiddleware,
} from 'tenantUser/redux/middleWares';
// import reduxDevTools from 'core/redux/middleWares/reduxDevTools';
import { rootSaga } from 'core/saga/rootSaga';
import { fireBaseMiddleware } from './middleWares/fireBaseMiddleware';
import createSagaMiddleware from 'redux-saga';
import agreementObjectMiddleware from '../../agreementObject/redux/middleWares';
import agreementMiddleware from 'agreement/redux/middleWares';
import fileMiddleware from 'file/redux/middlewares';

const __DEV__ = process.env.NODE_ENV === 'development';
const REACT_APP_LABEL_ENV = process.env.REACT_APP_LABEL_ENV;

console.log(__DEV__ ? '%c [DEV]' : '%c [PROD]', 'color: green;');
console.info(`App type: ${REACT_APP_LABEL_ENV}`);

const sagaMiddleware = createSagaMiddleware({
    context: {
        appType: REACT_APP_LABEL_ENV,
    },
});

const middlewares = [
    windowSizeListenerMiddleware,
    larvaActionsQueueMiddleware,
    sagaMiddleware,
    thunk,
    refreshTokenMiddleware,
    logoutMiddleware,
    languageMiddleware,
    larvaWSConnectionsMiddleware,
    larvaWsRequestsMiddleware,
    menuMiddleware,
    fireBaseMiddleware,
    logoutMiddleware,
    changeLanguageMiddleware,
    agreementObjectMiddleware,
    agreementMiddleware,
    fileMiddleware,
];

const composeEnhancer = __DEV__
    ? composeWithDevTools({
          trace: true,
      })
    : compose;

const store = createStore(persistedRootReducer, composeEnhancer(applyMiddleware(...middlewares)));

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
