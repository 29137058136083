import { AUTH } from 'core/datasets/action';
import { Middleware } from 'redux';

export const logoutMiddleware: Middleware = () => (next) => (action) => {
    if (action?.type === AUTH.LOGOUT) {
        localStorage.clear();
    }

    next(action);
};
