import GraphQLClient from 'core/classes/GraphQLClient';
import WebClient from 'core/classes/WebClient';
import WebClientConfig from 'core/classes/WebClientConfig';
import { gqlUri } from 'core/config/gql/client';
import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { authActions, changeFieldValue, setLoginSuccess, startLogin } from 'tenantUser/redux/authActions';
import { AppState } from '../../../redux/rootReducer';
import { AxiosResponse } from 'axios';
import { coreActions } from '../../../redux/actions/coreActions';
import { NotificationAlert } from '../../../classes/NotificationAlert';
import { LoginGQLDocument } from 'tenantUser/api/gql/login';

export const useLoginFormData: LoginFormDataHook = () => {
    const dispatch = useDispatch();

    const [termsChecked, setTermsChecked] = useState(false);

    const handleTerms = () => {
        setTermsChecked((prev) => !prev);
    };

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(changeFieldValue(event));
        },
        [dispatch],
    );

    const email = useSelector(({ tempAuth }: AppState) => tempAuth.email);
    const password = useSelector(({ tempAuth }: AppState) => tempAuth.password);

    const handleLogin = async () => {
        const config = new WebClientConfig({ baseURL: gqlUri });
        const client = new GraphQLClient(new WebClient(config));

        dispatch(startLogin());

        let message = 'notices.criticalError';

        try {
            const variables = { input: { email: email?.value, password: password?.value } };
            const response = (await client.request(LoginGQLDocument, variables)) as AxiosResponse;

            if (response.status === 200) {
                if (response.data?.data?.LogIn) {
                    const loginData = response.data?.data?.LogIn;

                    dispatch(setLoginSuccess(loginData));
                    return;
                }

                if (response.data?.errors && response.data?.errors?.[0]?.path?.[0] === 'LogIn') {
                    message = 'notices.wrongCredentials';
                }
            }

            dispatch(coreActions.notification.set({ message: message, level: NotificationAlert.ERROR }));
        } catch (error) {
            console.error(error);

            dispatch(coreActions.notification.set({ message: message, level: NotificationAlert.ERROR }));
        } finally {
            dispatch(authActions.clearLoadingState());
        }
    };

    const handleKeyEnterPressed = ({ nativeEvent }: KeyboardEvent<HTMLInputElement>) => {
        if (!!nativeEvent && 'key' in nativeEvent && 'Enter' === nativeEvent.key) handleLogin();
    };

    return {
        handleChange,
        handleLogin,
        handleTerms,
        termsChecked,
        handleKeyEnterPressed,
    };
};
export default useLoginFormData;

type LoginFormDataHook = () => LoginFormDataReturn;
type LoginFormDataReturn = {
    handleChange: HandleEvent;
    termsChecked: boolean;
    handleTerms: () => void;
    handleLogin: () => void;
    // userData: UserData;
    handleKeyEnterPressed: (event: KeyboardEvent<HTMLInputElement>) => void;
};
type HandleEvent = (event: ChangeEvent<HTMLInputElement>) => void;
