import { Middleware } from 'redux';
import { AppState } from '../../../core/redux/rootReducer';
import { CORE, FILE, FILE_TEMP } from '../../../core/datasets/action';
import { filesActions } from '../fileActions';
import IFileData from '../../types/IFileData';
import FileDto from '../../classes/FileDto';
import { ViewVariantType } from '../../../core/classes/ViewVariantType';

const fileMiddleware: Middleware =
    ({ getState, dispatch }) =>
    (next) =>
    (action) => {
        next(action);

        const state: AppState = getState();

        switch (action?.type) {
            case CORE.RECEIVED_ERROR_GRAPHQL_RESPONSE: {
                dispatch(filesActions.changeDownloadingState(false));

                const { response, callbackType } = action.payload ?? {};

                if (FILE_TEMP.UPDATE_SELECTED_FILE_DATA === callbackType && response?.data?.data) {
                    const selectedId = state.tempFiles.selected?.id || null;
                    const fileList = state.files.list;

                    if (!selectedId || !fileList.length) return;

                    const nextFileList = fileList.map((file) => {
                        if (Number(file.meta?.id) === selectedId) {
                            return { ...file, leftBlockVariant: ViewVariantType.DANGER };
                        }

                        return { ...file };
                    });

                    dispatch(filesActions.updateFilesListViewData(nextFileList));
                }
                break;
            }

            case FILE.ALL_FILES_RECEIVED: {
                const selectedFileId = state.tempFiles.selected?.id;
                const files = action.payload.data.getFiles;

                const filesMap: { [id: number]: IFileData } = {};
                let selectedFile: null | IFileData = null;

                for (const file of files) {
                    if (!filesMap[file.id]) {
                        const fileData = new FileDto(file).all;
                        filesMap[file.id] = fileData;

                        if (!selectedFileId && !selectedFile) selectedFile = fileData;
                    }
                }

                if (selectedFileId && selectedFileId in filesMap) {
                    selectedFile = filesMap[selectedFileId];
                }

                dispatch(filesActions.changeLoadingState(false));

                if (selectedFile) {
                    dispatch(filesActions.selectFile(selectedFile));
                }
                break;
            }

            case FILE_TEMP.LIST_ITEM_SELECTED_BY_ID: {
                const id = action.payload;
                const selectedId = state.tempFiles.selected?.id;

                if (selectedId === id) return;

                dispatch(filesActions.resetSelected());

                const filesMap = state.files.map;
                if (filesMap[id]) dispatch(filesActions.selectFile(filesMap[id]));
                break;
            }
        }
    };

export default fileMiddleware;
