import { LarvaError, LarvaErrorEvent } from '@larva.io/clouddevice';
import { getToken } from 'core/utils/localStorage/localStorage';
import LarvaWsClientWrapper from 'smartHome/larva/classes/LarvaWsClientWrapper';
import LarvaDeviceProps from 'smartHome/larva/types/LarvaDeviceProps';
import { LarvaErrorListener, LarvaEventListener } from '@larva.io/clouddevice/dist/types/interface';
import larvaConfig from 'core/config/larva/general';

const connectionsMap = new Map<string, LarvaWsClientWrapper>();

const defaultErrorListener: LarvaErrorListener = (event: LarvaErrorEvent) => {
    console.log(`Error in larva websocket connection`, { event });
    throw new LarvaError('Larva websocket error', 'Larva websocket error');
};

const defaultOpenConnectionListener: LarvaEventListener = (data: CustomEvent) => {
    if (process.env.NODE_ENV !== 'production') console.log('Larva websocket connection opened', { data });
};

const createDeviceConnection = async ({
    deviceId,
    unitId,
    errorListener,
    openListener,
}: LarvaDeviceProps): Promise<LarvaWsClientWrapper> => {
    const token = getToken();
    const options = {
        server: larvaConfig.deviceProxy,
        timeout: larvaConfig.deviceTimeout,
    };

    const fullId = `${unitId}-${deviceId}`;

    let connection: LarvaWsClientWrapper | undefined;

    connection = connectionsMap.get(fullId);

    if (!connection) {
        connection = new LarvaWsClientWrapper(
            deviceId,
            unitId,
            token,
            errorListener || defaultErrorListener,
            openListener || defaultOpenConnectionListener,
            options,
        );
    }

    if (connection.readyState() === 3) {
        await connection.open();
    }

    return connection;
};

export const clearAllWsConnections = (): void => {
    connectionsMap.clear();
};

export default createDeviceConnection;
