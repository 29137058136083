import { agreementsReducer } from 'agreement/redux/agreementsReducer';
import { tempAgreementReducer } from 'agreement/redux/tempAgreementReducer';
import { agreementObjectsReducer } from 'agreementObject/redux/agreementObjectsReducer';
import { coreReducer } from 'core/redux/reducers/coreReducer';
import { menuReducer } from 'core/redux/reducers/menuReducer';
import { filesReducer } from 'file/redux/filesReducer';
import { tempFilesReducer } from 'file/redux/tempFilesReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { rentalObjectsReducer } from 'rentalObject/redux/rentalObjectsReducer';
import { messagingReducer } from 'messaging/redux/messagingReducer';
import { tempLarvaReducer } from 'smartHome/larva/redux/tempLarvaReducer';
import { tempAuthReducer } from 'tenantUser/redux/tempAuthReducer';
import { ACTION } from '../datasets/action';
import { AppActions } from 'core/types/redux/actions';
import { useProfileReducer } from 'tenantUser/redux/userProfileReducer';
import { authReducer } from 'tenantUser/redux/authReducer';
import { filtersReducer } from './reducers/filtersReducer';
import { larvaReducer } from 'smartHome/larva/redux/larvaReducer';
import { serviceRequestReducer } from 'serviceRequest/redux/serviceRequestReducer';
import { slideInContextReducer } from './reducers/slideInContextReducer';
import { combineReducers } from 'redux';
import { tempInvoicesReducer } from 'invoice/redux/tempInvoicesReducer';
import { invoicesReducer } from 'invoice/redux/invoicesReducer';
import { environmentReducer } from 'environment/redux/environmentReducer';
import { tipsAndTricksReducer } from 'tipsAndTricks/redux/tipsAndTricksReducer';
import { co2Reducer } from 'co2Meters/redux/co2Reducer';
import { inceptionReducer } from 'smartHome/inception/redux/inceptionReducer';
import { extraServicesReducer } from 'extraService/redux/extraServicesReducer';
import { saltoReducer } from 'salto/redux/saltoReducer';
import { firebaseReducer } from '../../firebase/redux/firebaseReducer';

const rootPersistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'core',
        'tempAgreements',
        'tempInvoices',
        'slideInContext',
        'tempLarva',
        'tempAuth',
        'tempFiles',
        'firebase',
        'menu',
        'tipsAndTricks',
        'co2Meters',
        'filters',
        'extraServices',
        'salto',
    ],
};

const appReducer = combineReducers({
    core: coreReducer,
    rentalObjects: rentalObjectsReducer,
    messaging: messagingReducer,
    userProfile: useProfileReducer,
    auth: authReducer,
    tempAuth: tempAuthReducer,
    filters: filtersReducer,
    larva: larvaReducer,
    tempLarva: tempLarvaReducer,
    serviceRequests: serviceRequestReducer,
    slideInContext: slideInContextReducer,
    invoices: invoicesReducer,
    tempInvoices: tempInvoicesReducer,
    agreements: agreementsReducer,
    tempAgreements: tempAgreementReducer,
    agreementObjects: agreementObjectsReducer,
    files: filesReducer,
    tempFiles: tempFilesReducer,
    firebase: firebaseReducer,
    environment: environmentReducer,
    menu: menuReducer,
    tipsAndTricks: tipsAndTricksReducer,
    co2Meters: co2Reducer,
    inception: inceptionReducer,
    extraServices: extraServicesReducer,
    salto: saltoReducer,
});

export const rootReducer = (state: AppState | undefined, action: AppActions) => {
    if (action.type === ACTION.AUTH.LOGOUT) {
        // clear store
        state = undefined;
    }
    return appReducer(state, action);
};

export const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);
export type AppState = ReturnType<typeof appReducer>;
